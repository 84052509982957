/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCart, setCourse } from "../../store/reducers";
import { CourseModel } from "../../store/types";
import {
  AddToCartApi,
  FetchCourseApi,
  FetchWatchUrl,
} from "../../store/api/course.api";
import { RateBar } from "../../components/RateBar";
import { AuthorSection } from "../../components/AuthorSection";
import playerjs from "player.js";
import { recordEvent } from "../../utils/analytics";
import { toast } from "react-toastify";
import { DisplayDescription } from "../../components/utilityComponents";

export const Preview = () => {
  useEffect(() => {
    recordEvent("preview-page", {
      event_type: "Visited",
      event_name: "visited",
      user_properties: {},
    });
  }, []);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();
  const { course, user } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    if (id) {
      onFetchPreviewCourse();
    }
  }, [id]);

  const onPlayerReady = () => {
    try {
      var iframe = document.querySelector("iframe");
      if (iframe) {
        const player = new playerjs.Player(iframe as HTMLElement);
        if (player) {
          player.on("ready", () => {
            player.on("play", () => {
              // send mark event
            });
          });

          // player.on("timeupdate", (tm) => {
          //   player.getDuration((duration: any) => {
          //     // console.log(duration);
          //   });
          // });

          player.on("ended", () => {
            // next video
            // console.log("ended playing");
          });
        }
      }
    } catch (e) {}
  };

  const onFetchPreviewCourse = async () => {
    try {
      if (id) {
        const response = await FetchCourseApi(id);
        if (response.data) {
          const course = response.data as CourseModel;
          recordEvent("preview-page", {
            event_type: "Button_Clicked",
            event_name: "preview_course",
            user_properties: {
              course_id: course.Id,
              title: course.Title,
              price: course.OfferPrice,
            },
          });
          dispatch(setCourse(course));
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast("course is not available to preview!", {
            type: "error",
          });
        }
      }
    } catch (error) {
      toast("error on retrieving course!", {
        type: "error",
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const onAddToCart = async (courseId: string) => {
    try {
      if (user.UserId) {
        recordEvent("preview-page", {
          event_type: "Button_Clicked",
          event_name: "visited",
          user_properties: {
            user_id: user.UserId,
            email: user.Email,
            course_id: courseId,
          },
        });

        const status = await AddToCartApi(courseId, 1);
        if (status === 200) {
          dispatch(setCart(""));
          navigate("/cart");
        } else {
          localStorage.removeItem("token");
        }
      } else {
        dispatch(setCart(courseId));
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const courseOutline = () => {
    if (course && Array.isArray(course.Outlines)) {
      return course.Outlines.map((outline, index) => (
        <div
          tabIndex={index}
          className="collapse collapse-plus bg-base-200"
          style={{ marginTop: 5 }}
        >
          <div className="collapse-title text-xl font-medium">
            {`${index + 1}. ${outline.Title}`}
          </div>
          <div className="collapse-content">
            <ul role="list" className="divide-y divide-gray-100">
              {outline.Episodes.map((episode) => (
                <li className="flex justify-between gap-x-6 py-5 bg-white p-10 rounded">
                  <div className="flex min-w-0 gap-x-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M452-160q6 20 16.5 41.5T490-80H200q-33 0-56.5-23.5T120-160v-640q0-33 23.5-56.5T200-880h480q33 0 56.5 23.5T760-800v284q-18-2-40-2t-40 2v-284H480v280l-100-60-100 60v-280h-80v640h252ZM720-40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40Zm-50-100 160-100-160-100v200ZM280-800h200-200Zm172 0H200h480-240 12Z" />
                    </svg>
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {episode.Title}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ));
    }
  };

  if (course && course.Id && !isLoading) {
    return (
      <>
        <div className="bg-white">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
            <div>
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                {course.Title}
              </h1>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <RateBar
                  students={course.StudentCount || 0}
                  rating={0}
                  average={course.RatingAverage || 0}
                />
              </div>
              {DisplayDescription(course.Description)}
              <div
                style={{
                  display: "flex",
                  fontSize: 16,
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <p className="text-left text-2xl">
                  <b>{`$${course.OfferPrice} `}</b>
                  <s>{` $${course.Price}`}</s>
                </p>
              </div>
              <div className="card-actions">
                <button
                  className="btn bg-blue-700 text-white hover:bg-violet-950  hover:text-white "
                  onClick={() => onAddToCart(course.Id)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    fill="#FFF"
                  >
                    <path d="M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z" />
                  </svg>
                  Buy Now
                </button>
              </div>

              <div>
                <p className="text-left text-xl mt-10 text-gray-800">
                  What you need to know to start this course
                </p>
                <p className="text-left mt-2 text-gray-600">
                  {course.Prerequisites}
                </p>
                <p className="text-left text-xl mt-10 text-gray-800">
                  What you will learn from this course:
                </p>
              </div>
              <dl className="mt-8 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                {Array.isArray(course.Benefits) &&
                  course.Benefits.map((highlight) => (
                    <div
                      className="border-t border-gray-200 pt-2"
                      key={highlight.Title}
                    >
                      <dt className="font-medium text-gray-900">
                        <div
                          className="gap-2"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 -960 960 960"
                            width="24"
                          >
                            <path d="M268-240 42-466l57-56 170 170 56 56-57 56Zm226 0L268-466l56-57 170 170 368-368 56 57-424 424Zm0-226-57-56 198-198 57 56-198 198Z" />
                          </svg>
                          {highlight.Title}
                        </div>
                      </dt>
                      <dd className="mt-2 text-sm text-gray-500">
                        {highlight.Description}
                      </dd>
                    </div>
                  ))}
              </dl>
            </div>
            <div
              className="grid grid-cols-1 grid-rows-1"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
                paddingRight: 20,
              }}
            >
              <div className="items-center justify-center">
                <iframe
                  id="iframe"
                  onLoad={() => {
                    onPlayerReady();
                  }}
                  style={{
                    borderRadius: 15,
                  }}
                  width="500"
                  height="280"
                  src={course.PreviewId}
                  title="Preview Course"
                  loading="lazy"
                  allowFullScreen={true}
                  allow="accelerometer;gyroscope;encrypted-media;picture-in-picture;"
                />
                <div style={{ marginTop: 30 }}>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="m460-380 280-180-280-180v360ZM320-240q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320Zm0-80h480v-480H320v480ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z" />
                    </svg>
                    <p className="mt-2 text-xl  tracking-tight text-gray-900 ml-4">
                      {(Number(course.VideoLength) / 60).toFixed(2)} Hours On
                      demand Videos
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M438-226 296-368l58-58 84 84 168-168 58 58-226 226ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z" />
                    </svg>
                    <p className="mt-2 text-xl  tracking-tight text-gray-900 ml-4">
                      Updated on {dayjs(course.UpdatedAt).format("DD MMM YYYY")}
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M80-680v-80q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v80h-80v-80H160v80H80Zm240 560v-80H160q-33 0-56.5-23.5T80-280v-80h80v80h640v-80h80v80q0 33-23.5 56.5T800-200H640v80H320Zm160-400Zm-288 0 104-104-56-56L80-520l160 160 56-56-104-104Zm576 0L664-416l56 56 160-160-160-160-56 56 104 104Z" />
                    </svg>
                    <p className="mt-2 text-xl  tracking-tight text-gray-900 ml-4">
                      Updated Source Code
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v240h-80v-240H160v525l46-45h394v80H240L80-80Zm80-240v-480 480ZM760-40v-200h-80v-240h200l-68 160h88L760-40Z" />
                    </svg>
                    <p className="mt-2 text-xl  tracking-tight text-gray-900 ml-4">
                      Instant Support in Discord
                    </p>
                  </div>
                  <AuthorSection course={course} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white pb-10">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Course Outline
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                It is a completely hands on course where we have covered all
                edge cases from professional developer prospective to build the
                project.
              </p>
            </div>
            <div className="pt-10">{course && courseOutline()}</div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div
        className="flex justify-center items-center flex-col"
        style={{ height: "80vh" }}
      >
        {!isLoading ? (
          <div className="gap-10 flex flex-center flex-col">
            <p className="text-sm">
              Course not found or it is not ready to Preview...
            </p>
            <button
              className="btn bg-black text-white hover:bg-amber-600  hover:text-white"
              onClick={() => navigate(`/`)}
            >
              Back to Home
            </button>
          </div>
        ) : (
          <div
            className="gap-10"
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <progress className="progress w-56 bg-amber-500 text-lime-500"></progress>
            <p className="text-sm">Loading Course Preview...</p>
          </div>
        )}
      </div>
    );
  }
};
