export const DisplayDescription = (description: string) => {
  if (description) {
    const stringArr = description.split("*");
    let elements = Array<React.ReactElement>();
    stringArr.forEach((item, index) => {
      if (index < 1) {
        elements.push(<p className="mt-6 font-medium text-gray-900">{item}</p>);
      } else {
        elements.push(
          <li className="ml-4 font-medium text-gray-600 mt-4">{item}</li>
        );
      }
    });
    return elements;
  }
  return <></>;
};
