import React from "react";

interface RateBarProps {
  rating: number;
  students: number;
  average: number;
}

export const RateBar: React.FC<RateBarProps> = ({
  rating,
  students,
  average,
}) => {
  return (
    <>
      <div style={{}}>
        <div
          className="rating"
          style={{
            padding: 10,
            borderRadius: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {rating > 0 && (
            <p style={{ marginRight: 5, fontSize: 16, fontWeight: "600" }}>
              [{rating}]
            </p>
          )}

          <input
            type="radio"
            name="rating-2"
            className="mask mask-star-2 bg-orange-400"
            checked
            disabled
          />
          <input
            type="radio"
            name="rating-2"
            className="mask mask-star-2 bg-orange-400"
            checked
            disabled
          />
          <input
            type="radio"
            name="rating-2"
            className="mask mask-star-2 bg-orange-400"
            checked
            disabled
          />
          <input
            type="radio"
            name="rating-2"
            className="mask mask-star-2 bg-orange-400"
            checked
            disabled
          />

          {students > 0 && (
            <p style={{ marginLeft: 10 }}>{students} Students</p>
          )}
        </div>
      </div>
    </>
  );
};
