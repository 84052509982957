import { useEffect, useState } from "react";
import { FetchMeApi, SignupApi } from "../../store/api/user.api";
import { useNavigate } from "react-router-dom";
import { userLogin, userProfile } from "../../store/reducers";
import { UserModel } from "../../store/types";
import { useAppSelector, useAppDispatch } from "../../store/hooks";

import { toast } from "react-toastify";
import { recordEvent } from "../../utils/analytics";

export const SignUp = () => {
  useEffect(() => {
    recordEvent("sign-up-page", {
      event_type: "Visited",
      event_name: "visited",
      user_properties: {},
    });
  }, []);

  const [tnc, setTnC] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { cart } = useAppSelector((state) => state.userReducer);

  const onTapSignup = async () => {
    if (firstName === "" || lastName === "") {
      toast("Please enter first name & last name!", {
        type: "error",
      });
      return;
    }
    if (email === "" || password === "") {
      toast("Please enter email address and password!", {
        type: "error",
      });
      return;
    }

    if (tnc === "") {
      toast("Please accept Terms and Conditions before Join!", {
        type: "error",
      });
      return;
    }

    recordEvent("sign-up-page", {
      event_type: "Button_Clicked",
      event_name: "sign-up",
      user_properties: {
        email: email,
        first_name: firstName,
        last_name: lastName,
      },
    });
    try {
      const userData = await SignupApi(email, password, firstName, lastName);
      console.log(userData);
      if (userData.data.token) {
        localStorage.setItem("token", userData.data.token);
        dispatch(userLogin(userData.data as UserModel));
        await onFetchMe();
        if (Array.isArray(cart) && cart.length > 0) {
          navigate("/cart");
          return;
        }
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFetchMe = async () => {
    try {
      const response = await FetchMeApi();
      if (response.data) {
        dispatch(userProfile(response.data as UserModel));
      } else {
        localStorage.removeItem("token");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8"
      style={{
        height: "80vh",
      }}
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Get Started
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full">
        {/* <div className="space-y-6">
          <div>
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              First Name
            </label>
            <div className="mt-2">
              <input
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                required
                placeholder="First Name"
                className="input w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Last Name
            </label>
            <div className="mt-2">
              <input
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                required
                placeholder="Last Name"
                className="input w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required
                placeholder="Email ID"
                className="input w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                required
                autoComplete="none"
                placeholder="Password"
                className="input w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div
            className="text-left text-sm"
            style={{ alignItems: "center", display: "flex" }}
          >
            <input
              type="checkbox"
              onChange={(e) => setTnC(e.target.value)}
              className="checkbox mr-5"
            />
            I accept{" "}
            <a
              href="/tnc"
              className="ml-2 leading-6 text-blue-700 hover:text-indigo-500"
            >
              Terms and conditions of Code Episodes
            </a>
          </div>

          <div>
            <button
              onClick={onTapSignup}
              className="btn btn-wide w-full bg-blue-700 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Join Code Episodes
            </button>
          </div>
        </div> */}
        <h1 style={{ fontSize: "2.0rem" }}>
          {" "}
          We are migrating to
          <a
            href="https://codewithjay.com"
            style={{ margin: 5, fontWeight: "bold" }}
          >
            {" "}
            CodeWithJay.com{" "}
          </a>
          with more features and new courses, including our Career Booster and
          Mentoring programs. All existing users will still be able to access
          the platform using the same email and password and after successful
          migration we will invite you to access CodeWithJay
        </h1>

        <p className="mt-10 text-center text-sm text-gray-500">
          Existing member?
          <a
            href="/login"
            className="ml-2 font-semibold leading-6 text-blue-700 hover:text-indigo-500"
          >
            Use Login
          </a>
        </p>
      </div>
    </div>
  );
};
