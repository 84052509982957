import { Amount } from "../store/types";

export const CartTotal = (amount?: Amount) => {
  let total = 0.0;
  let tax = 0.0;
  let fee = 0.0;
  let totalItem = 0;

  if (amount) {
    total = amount.total + amount.fee + amount.tax;
    fee = amount.fee;
    totalItem = amount.totalItems;
    tax = amount.tax;
  }

  return { total, tax, totalItem, fee };
};
