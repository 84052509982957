import React from "react";
import { CourseDetails } from "../types";
import { CourseModel } from "../store/types";

interface AuthorSectionProps {
  course: CourseModel;
}

export const AuthorSection: React.FC<AuthorSectionProps> = ({ course }) => {
  return (
    <div
      className="flex min-w-0 gap-x-4 mt-20"
      style={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <img
        className="h-32 w-32 flex-none rounded-full bg-gray-50"
        src={course.AuthorImgUrl}
        alt=""
      />

      <div
        className="min-w-0 flex-auto"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <p className="text-left text-gray-600">Author</p>
        <p className="text-sm font-semibold leading-6 text-gray-900 text-xl">
          {course.Author}
        </p>
        <p className="mt-1 text-gray-500">{course.AuthorDesignation}</p>
        <p className="text-left text-sm pt-2 mr-30">{course.AuthorBio}</p>
      </div>
    </div>
  );
};
