/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";
import { Login } from "./login";
import { Landing } from "./landing";
import { Preview } from "./preview";
import { SignUp } from "./signup";
import { DashBoard } from "./dashboard";
import { Account } from "./account";
import { Cart } from "./cart";
import { Watch } from "./watch";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { Payment } from "./cart/Payment";
import { Verify } from "./cart/Verify";
import { useEffect } from "react";
import { FetchMeApi } from "../store/api/user.api";
import { userProfile } from "../store/reducers";
import { UserModel } from "../store/types";
import { Logout } from "./logout";
import { ResetPassword } from "./login/ResetPassword";
import { SetNewPassword } from "./login/SetNewPassword";
import { TermsOfUse } from "./privacy/termsOfUse";
import { PrivacyPolicy } from "./privacy/privacyPolicy";
import { AboutUs } from "./about";
import { ContactUs } from "./contact";
import { Cancellation } from "./cancellation/termsOfUse";
import { VerifyIdentity } from "./signup/verifyIdenty";

interface ValidRoutesProps {}

const ValidRoutes: React.FC<ValidRoutesProps> = () => {
  const user = useAppSelector((state) => state.userReducer).user;

  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token !== null) {
      onFetchMe();
    }
  }, []);

  const onFetchMe = async () => {
    try {
      const response = await FetchMeApi();
      if (response.data) {
        dispatch(userProfile(response.data as UserModel));
      } else {
        localStorage.removeItem("token");
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (user && user.token && user.UserId) {
    return (
      <Routes>
        <Route path="/watch/:id" element={<Watch />} />
        <Route path="/dashboard" element={<DashBoard />} />
        <Route path="/account" element={<Account />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/congrats" element={<Verify />} />
        <Route path="/preview/:id" element={<Preview />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/tnc" element={<TermsOfUse />} />
        <Route path="/cancellation" element={<Cancellation />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/" element={<DashBoard />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/new-password" element={<SetNewPassword />} />
        <Route path="/i-am-not-robot" element={<VerifyIdentity />} />

        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/new-password" element={<SetNewPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/preview/:id" element={<Preview />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/tnc" element={<TermsOfUse />} />
        <Route path="/cancellation" element={<Cancellation />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/i-am-not-robot" element={<VerifyIdentity />} />

        <Route path="/" element={<Landing />} />

        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    );
  }
};

interface RouterHandlerProps {}

export const RouterHandler: React.FC<RouterHandlerProps> = ({}) => {
  return (
    <BrowserRouter>
      {/* <div style={{ position: "fixed" }}> */}
      <Navbar />
      {/* </div> */}
      <ValidRoutes />
      <Footer />
    </BrowserRouter>
  );
};
