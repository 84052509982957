interface Review {
  id: number;
  title: string;
  desc: string;
  rating: number;
}
export const Reviews = () => {
  return (
    <div className="p-5">
      <div className="pt-10">
        <p className="text-left text-xl pb-5">Course Reviews:</p>

        <div
          className="justify-start"
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="avatar placeholder mr-5">
            <div className="bg-neutral text-neutral-content rounded-full w-12">
              <span>AN</span>
            </div>
          </div>
          <div>
            <p className="text-left text-medium">It will be available soon!</p>
            <p className="text-left"></p>
          </div>
        </div>
        {/* <button className="btn btn-wide mt-10 bg-black text-white hover:bg-indigo-600 hover:text-white">
          Check Reviews
        </button> */}
      </div>
    </div>
  );
};
