import { useEffect } from "react";
import { recordEvent } from "../../utils/analytics";

export const PrivacyPolicy = () => {
  useEffect(() => {
    recordEvent("privacy-policy-page", {
      event_type: "Visited",
      event_name: "visited",
      user_properties: {},
    });
  }, []);

  return (
    <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
        <p className="text-4xl">Privacy Policy</p>

        <figure className="mt-10">
          <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
            <p className="text-justify">
              This page informs you of our policies regarding the collection,
              use, and disclosure of Personal Information we receive from you.
              We use your Personal Information only for providing and improving
              the Site. By using the Site, you agree to the collection and use
              of information by this policy.
            </p>
          </blockquote>

          <p className="text-2xl mt-5 mb-5">Information We Collect</p>
          <p>
            <ul className="list-disc mt-10 text-justify mt-4">
              <li className=" m-5 mt-2">
                Personal Information: When you register for courses, make
                purchases, or interact with our website, we may collect
                personally identifiable information, including but not limited
                to your name, email address, billing address, and payment
                information.
              </li>
              <li className=" m-5 mt-2">
                Usage Data: We may also collect information about how you access
                and use the Site. This may include your computer's Internet
                Protocol address (IP address), browser type, browser version,
                the pages of our Site that you visit, the time and date of your
                visit, the time spent on those pages, and other diagnostic data.
              </li>
            </ul>
          </p>

          <p className="text-2xl mt-5 mb-5">Use of Information</p>
          <p>
            We use the collected information for various purposes, including but
            not limited to:
            <ul className="list-disc mt-10 text-justify mt-4">
              <li className=" m-5 mt-2">Providing and maintaining the Site.</li>
              <li className=" m-5 mt-2">
                Facilitating course registration and purchases.
              </li>
              <li className=" m-5 mt-2">
                Communicating with you about your account and course updates.
              </li>
              <li className=" m-5 mt-2">
                Personalizing your experience on the Site.
              </li>
              <li className=" m-5 mt-2">
                Analyzing usage data to improve our services and offerings.
              </li>
              <li className=" m-5 mt-2">
                Detecting, preventing, and addressing technical issues or
                fraudulent activities.
              </li>
            </ul>
          </p>
          <p className="text-2xl mt-5 mb-5">Data Security</p>
          <p>
            We take appropriate measures to protect the security of your
            Personal Information. However, please be aware that no method of
            transmission over the Internet or electronic storage is 100% secure.
            While we strive to use commercially acceptable means to protect your
            Personal Information, we cannot guarantee its absolute security.
          </p>

          <p className="text-2xl mt-5 mb-5">Data Sharing</p>
          <p>
            We may share your Personal Information with third-party service
            providers to facilitate our services (e.g., payment processors,
            hosting providers). These service providers are contractually
            obligated to use your Personal Information only as necessary to
            provide the services to us and are prohibited from using it for any
            other purpose.
          </p>
          <p className="text-2xl mt-5 mb-5">
            Cookies and Tracking Technologies
          </p>
          <p>
            Our Site may use cookies and similar tracking technologies to
            enhance your browsing experience and analyze usage data. You have
            the option to refuse cookies by adjusting your browser settings, but
            please note that certain features of the Site may not function
            properly if you do so.
          </p>
          <p className="text-2xl mt-5 mb-5">Your Rights</p>
          <p>
            You have the right to access, correct, or delete your Personal
            Information. You may also request that we restrict the processing of
            your Personal Information or object to its processing. Please
            contact us if you wish to exercise any of these rights.
          </p>
          <p className="text-2xl mt-5 mb-5">Changes to This Privacy Policy</p>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <p className="text-2xl mt-5 mb-5">Contact Us</p>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at support@codepisodes.com
          </p>
        </figure>
      </div>
    </section>
  );
};
