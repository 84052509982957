import axios from "axios";
import { BASE_URL } from "./appConst";
import { AddressInput, ResponseModel } from "../types";
import { axiosAuth } from "./common";

export const SignupApi = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string
) => {
  try {
    const response = await axios.post(`${BASE_URL}/register`, {
      firstName,
      lastName,
      email,
      password,
    });
    return response.data as ResponseModel;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};

export const LoginApi = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${BASE_URL}/login`, {
      email,
      password,
    });
    if (response.status > 200) {
      throw new Error("incorrect user id password");
    }
    return response.data as ResponseModel;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};

export const FetchMeApi = async () => {
  try {
    const axios = axiosAuth();
    const response = await axios.get(`${BASE_URL}/me`);
    return response.data as ResponseModel;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};

export const UpdateAddressApi = async (input: AddressInput) => {
  try {
    const axios = axiosAuth();
    const response = await axios.post(`${BASE_URL}/address`, input);
    return response.status;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};

export const ChangePassword = async (password: string) => {
  try {
    const axios = axiosAuth();
    const response = await axios.post(`${BASE_URL}/change-password`, {
      password,
    });
    return response.status;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};

export const onResetPassword = async (email: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/reset-password/${email}`);
    return response.status;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};

export const onSetNewPassword = async (
  email: string,
  password: string,
  code: string
) => {
  try {
    const response = await axios.post(`${BASE_URL}/set-password`, {
      email,
      password,
      code,
    });
    return response.status;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};

export const ContactUsApi = async (
  email: string,
  firstName: string,
  lastName: string,
  message: string
) => {
  try {
    const response = await axios.post(`${BASE_URL}/contact`, {
      firstName,
      lastName,
      email,
      message,
    });
    return response.data as ResponseModel;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};

export const VerifyIdentityApi = async (email: string, code: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/verify/${email}/${code} `);
    return response.status;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};
