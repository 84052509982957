import * as amplitude from "@amplitude/analytics-browser";

interface EventProperty {
  event_type: "Button_Clicked" | "Visited";
  event_name: string;
  user_properties: Record<string, any>;
  app_version?: "1.0.0";
  platform?: "Web";
}

export const recordEvent = (eventName: string, properties: EventProperty) => {
  amplitude.track(eventName, properties);
};
