/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { AvailableCourses } from "../../components/AvailableCourses";
import { YourCourses } from "./YourCourses";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect } from "react";
import { FetchMeApi } from "../../store/api/user.api";
import { setCourses, userProfile } from "../../store/reducers";
import { CourseModel, UserModel } from "../../store/types";
import { FetchPublicCoursesApi } from "../../store/api/course.api";
import avatar from "../../img/avatar.jpg";
import { recordEvent } from "../../utils/analytics";

export const DashBoard = () => {
  useEffect(() => {
    recordEvent("dashboard-page", {
      event_type: "Visited",
      event_name: "visited",
      user_properties: {},
    });
  }, []);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { user, courses } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    onFetchMe();
    onFetchCourses();
  }, []);

  const onFetchMe = async () => {
    try {
      const response = await FetchMeApi();
      if (response.data) {
        dispatch(userProfile(response.data as UserModel));
      } else {
        localStorage.removeItem("token");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFetchCourses = async () => {
    try {
      const response = await FetchPublicCoursesApi();
      if (response.data) {
        dispatch(setCourses(response.data as CourseModel[]));
      } else {
        localStorage.removeItem("token");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const continueLearn = () => {
    if (user.LastCourse) {
      return (
        <>
          <h1 className="text-3xl font-bold">Continue Learning!</h1>

          <p className="py-6">Continue learn where you left last time!</p>
          <button
            className="btn bg-black text-white hover:text-black hover:bg-yellow-500"
            onClick={() => navigate(`/watch/${""}`)}
          >
            Continue Watch
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              fill="#FFF"
            >
              <path d="m380-300 280-180-280-180v360ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
            </svg>
          </button>
        </>
      );
    } else {
      return (
        <>
          <h1 className="text-3xl font-bold">
            Start a course and update your career!
          </h1>
          <p className="py-6">
            All courses are production level. You can gain professional
            experience during learning.
          </p>
        </>
      );
    }
  };

  return (
    <>
      <div className="bg-base-200 mt-16">
        <div className="hero-content flex-col lg:flex-row mt-10">
          <div
            className="flex min-w-0 gap-x-4 mt-10 ml-20 mb-10"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flex: 3,
            }}
          >
            <img
              className="h-32 w-32 flex-none rounded-full bg-gray-50"
              src={avatar}
              alt="avatar"
            />
            <div
              style={{
                display: "flex",
                width: 300,
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              Welcome Back
              <p className="text-left text-xl">
                {user ? user.FirstName : "Member"}
              </p>
            </div>
          </div>
          <div style={{ flex: 9 }}>{continueLearn()}</div>
        </div>
      </div>
      <div
        className="p-5 pl-16 gap-2"
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
          fill="#C70039"
        >
          <path d="m480-360 220-140-220-140v280ZM120-80q-33 0-56.5-23.5T40-160v-440h80v440h680v80H120Zm160-160q-33 0-56.5-23.5T200-320v-440h200v-80q0-33 23.5-56.5T480-920h160q33 0 56.5 23.5T720-840v80h200v440q0 33-23.5 56.5T840-240H280Zm0-80h560v-360H280v360Zm200-440h160v-80H480v80ZM280-320v-360 360Z" />
        </svg>
        <p className="text-left text-xl font-bold">Your Courses</p>
      </div>
      {user && (
        <YourCourses
          courses={Array.isArray(user.Courses) ? user.Courses : []}
        />
      )}
      <AvailableCourses
        courses={Array.isArray(courses) ? courses : []}
        yourCourses={Array.isArray(user.Courses) ? user.Courses : []}
      />
      <div className="mb-20" />
    </>
  );
};
