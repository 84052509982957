import dayjs from "dayjs";
import { AuthorSection } from "../../components/AuthorSection";
import { PlayList } from "./Playlist";
import { Reviews } from "../../components/Reviews";
import { RateBar } from "../../components/RateBar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FetchWatchUrl,
  MarkWatched,
  WatchCourseById,
} from "../../store/api/course.api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCourse, setOutlines } from "../../store/reducers";
import { CourseModel, Episode, Outlines } from "../../store/types";
import playerjs from "player.js";
import { recordEvent } from "../../utils/analytics";
import discord from "../../img/discord.svg";
import { toast } from "react-toastify";
import { DisplayDescription } from "../../components/utilityComponents";

export const Watch = () => {
  let { id } = useParams();

  useEffect(() => {
    recordEvent("watch-page", {
      event_type: "Visited",
      event_name: "visited",
      user_properties: {
        course_id: id,
        course_title: course.Title,
      },
    });
  }, []);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { course, outlines, user } = useAppSelector(
    (state) => state.userReducer
  );
  const [playbackList, setPlaybackList] = useState<Episode[]>();
  const [currentEpisode, setCurrentEpisode] = useState<Episode>();
  const [open, setOpen] = useState(false);

  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    if (id) {
      onFetchCurrentCourse();
    }
  }, [id]);

  useEffect(() => {
    preparePlaybackList();
  }, [course, outlines]);

  const preparePlaybackList = () => {
    if (Array.isArray(outlines)) {
      let allEpisodes: Episode[] = [];
      outlines.forEach((outline) => {
        if (Array.isArray(outline.Episodes)) {
          outline.Episodes.forEach((ep) => {
            if (ep.Order > 0) {
              allEpisodes.push(ep);
            }
          });
        }
      });
      const sortedEpisodes = allEpisodes.sort((a, b) =>
        a.Order < b.Order ? -1 : 1
      );
      setPlaybackList(sortedEpisodes);
    }
  };

  const autoPlayNextEpisode = async () => {
    if (Array.isArray(playbackList)) {
      let nextEpisode: Episode = {} as Episode;
      playbackList.forEach(async (episode) => {
        if (currentEpisode) {
          if (currentEpisode.Order < episode.Order && !nextEpisode.Order) {
            nextEpisode = episode;
            return;
          }
        }
      });
      if (nextEpisode) {
        console.log(nextEpisode);
        setCurrentEpisode(nextEpisode);
        setTimeout(() => {
          onWatchEpisode(nextEpisode);
        }, 1000);
      }
    }
  };

  const redirectToDashBoard = () => {
    toast(
      "Course not authorised to watch!. If you have purchased this course contact customer support!",
      {
        type: "error",
      }
    );

    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  const onFetchCurrentCourse = async () => {
    try {
      if (id) {
        const response = await WatchCourseById(id);
        if (response.status === 200) {
          if (response.data) {
            const currentCourse = response.data.data as CourseModel;
            setVideoUrl(currentCourse.PreviewId);
            dispatch(setCourse(currentCourse));
            return;
          }
        }
      }
      redirectToDashBoard();
    } catch (error) {
      console.log(error);
      redirectToDashBoard();
    }
  };

  const onWatchEpisode = async (episode: Episode) => {
    setCurrentEpisode(episode);
    try {
      recordEvent("watch-page", {
        event_type: "Button_Clicked",
        event_name: "watch-episode",
        user_properties: {
          course_title: course.Title,
          episode_title: episode.Title,
          user: user.Email,
        },
      });
      const data = await FetchWatchUrl(
        course.PK as string,
        episode.Id as string
      );
      if (data.data.url) {
        setVideoUrl(data.data.url);
      }
      return episode;
    } catch (error) {}
  };

  const onViewedEpisode = async () => {
    if (!currentEpisode) return;
    if (!currentEpisode.Watched) {
      try {
        const response = await MarkWatched(
          course.PK as string,
          currentEpisode.Id as string
        );
        const courseOutlines = response.data as Outlines[];
        dispatch(setOutlines(courseOutlines));
      } catch (error) {}
    }
  };

  const onPlayerReady = () => {
    try {
      var iframe = document.querySelector("iframe");
      if (iframe) {
        const player = new playerjs.Player(iframe as HTMLElement);
        if (player) {
          player.on("ready", () => {
            player.on("play", () => {
              onViewedEpisode().then(() => {});
            });
          });

          player.on("ended", () => {
            autoPlayNextEpisode().then(() => {
              setTimeout(() => {
                player.play();
              }, 2000);
            });
          });
        }
      }
    } catch (e) {}
  };

  const displayPlayerView = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flex: !open ? 9 : 11,
            width: !open ? "90hs" : "100hs",
            height: !open ? "80vh" : "90vh",
            padding: 10,
            zIndex: 0,
          }}
        >
          <iframe
            onLoad={() => onPlayerReady()}
            id="iframe"
            style={{
              borderRadius: 5,
            }}
            width="100%"
            height="100%"
            src={videoUrl}
            title="Current Episode"
            loading="lazy"
            allowFullScreen={true}
          />
        </div>
        {!open ? (
          <div
            className="flex"
            style={{
              display: "flex",
              flex: 2.6,
              zIndex: -1,
            }}
          ></div>
        ) : (
          <></>
        )}
      </>
    );
  };

  if (course) {
    return (
      <>
        <div
          className="bg-white mt-16"
          style={{
            zIndex: 0,
          }}
        >
          <div
            className="mx-auto grid grid-cols-2"
            style={{ display: "flex", zIndex: 0 }}
          >
            {displayPlayerView()}
            <PlayList
              onOpen={(isOpen: boolean) => {
                console.log(isOpen);
                setOpen(isOpen);
              }}
              currentEpisode={currentEpisode}
              onWatchEpisode={onWatchEpisode}
              playlist={Array.isArray(outlines) ? outlines : []}
            />
          </div>
        </div>
        <div className="p-10">
          <p className="text-xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            {course.Title}
          </p>
          {DisplayDescription(course.Description)}
          <RateBar
            students={course.StudentCount || 0}
            rating={course.RatingAverage || 0}
            average={course.RatingAverage || 0}
          />
          <div className="grid grid-cols-2 pr-80">
            <div>
              <p className="text-left text-xl mt-5 text-gray-800">
                What you will learn from this course:
              </p>
              <dl className="mt-8 grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                {Array.isArray(course.Benefits) &&
                  course.Benefits.map((highlight, index) => (
                    <div className="border-t border-gray-200 pt-2" key={index}>
                      <dt className="font-medium text-gray-900">
                        <div
                          className="gap-2"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 -960 960 960"
                            width="24"
                          >
                            <path d="M268-240 42-466l57-56 170 170 56 56-57 56Zm226 0L268-466l56-57 170 170 368-368 56 57-424 424Zm0-226-57-56 198-198 57 56-198 198Z" />
                          </svg>
                          {highlight.Title}
                        </div>
                      </dt>
                      <dd className="mt-2 text-sm text-gray-500">
                        {highlight.Description}
                      </dd>
                    </div>
                  ))}
              </dl>
              <Reviews />
            </div>
            <div className="p-10">
              <div
                style={{
                  marginTop: 30,
                }}
              >
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="m460-380 280-180-280-180v360ZM320-240q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320Zm0-80h480v-480H320v480ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z" />
                  </svg>
                  <p className="mt-2 text-xl  tracking-tight text-gray-900 ml-4">
                    {course.VideoLength} Hours On demand Videos
                  </p>
                </div>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="M438-226 296-368l58-58 84 84 168-168 58 58-226 226ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z" />
                  </svg>
                  <p className="mt-2 text-xl  tracking-tight text-gray-900 ml-4">
                    Updated on {dayjs(course.UpdatedAt).format("DD MMM YYYY")}
                  </p>
                </div>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="M80-680v-80q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v80h-80v-80H160v80H80Zm240 560v-80H160q-33 0-56.5-23.5T80-280v-80h80v80h640v-80h80v80q0 33-23.5 56.5T800-200H640v80H320Zm160-400Zm-288 0 104-104-56-56L80-520l160 160 56-56-104-104Zm576 0L664-416l56 56 160-160-160-160-56 56 104 104Z" />
                  </svg>
                  <p className="mt-2 text-xl  tracking-tight text-gray-900 ml-4">
                    Updated Source Code
                  </p>
                </div>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="M80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v240h-80v-240H160v525l46-45h394v80H240L80-80Zm80-240v-480 480ZM760-40v-200h-80v-240h200l-68 160h88L760-40Z" />
                  </svg>
                  <p className="mt-2 text-xl  tracking-tight text-gray-900 ml-4">
                    Instant Support in Discord{" "}
                    <a
                      href="https://discord.gg/6ZVpsN2R"
                      target="__blank"
                      className="btn rounded-full"
                    >
                      Join
                      <img src={discord} width={32} height={32} />
                    </a>
                  </p>
                </div>
                <AuthorSection course={course} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div
        className="flex justify-center items-center flex-col"
        style={{ height: 600 }}
      >
        <span className="loading loading-dots loading-lg"></span>
        <p className="text-xl">Loading...</p>
      </div>
    );
  }
};
