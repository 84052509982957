import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartModel, CourseModel, Outlines, UserModel } from "../types";
import { Cart } from "../../pages/cart";

export interface UserState {
  user: UserModel;
  courses: CourseModel[];
  course: CourseModel;
  cart: string[];
  outlines: Outlines[];
}

const initialState: UserState = {
  user: {} as UserModel,
  courses: {} as CourseModel[],
  course: {} as CourseModel,
  cart: {} as string[],
  outlines: {} as Outlines[],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogin(state, action: PayloadAction<UserModel>) {
      state.user = action.payload;
    },
    userLogout(state) {
      state.user = {} as UserModel;
    },
    userProfile(state, action: PayloadAction<UserModel>) {
      state.user = action.payload;
    },
    setCourses(state, action: PayloadAction<CourseModel[]>) {
      state.courses = action.payload;
    },
    setCourse(state, action: PayloadAction<CourseModel>) {
      state.course = action.payload;
      state.outlines = action.payload.Outlines;
    },
    setOutlines(state, action: PayloadAction<Outlines[]>) {
      state.outlines = action.payload;
    },
    setCart(state, action: PayloadAction<string>) {
      if (action.payload === "") {
        state.cart = [];
      } else {
        state.cart = [action.payload];
      }
    },
    setLogout(state, action: PayloadAction<{}>) {
      state = initialState;
    },
  },
});
export const {
  userLogin,
  userProfile,
  userLogout,
  setCourses,
  setCourse,
  setOutlines,
  setLogout,
  setCart,
} = userSlice.actions;
export default userSlice.reducer;
