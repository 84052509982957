import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { RateBar } from "./RateBar";
import { CourseModel } from "../store/types";
import { AddToCartApi } from "../store/api/course.api";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setCart } from "../store/reducers";
import { toast } from "react-toastify";

interface AvailableCoursesProps {
  courses: CourseModel[];
  yourCourses: CourseModel[];
}

export const AvailableCourses: React.FC<AvailableCoursesProps> = ({
  courses,
  yourCourses,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.userReducer);

  const checkIfPurchased = (courseId: string) => {
    return (
      Array.isArray(yourCourses) &&
      yourCourses.filter((course) => course.Id === courseId).length > 0
    );
  };

  const MockCourse = () => {
    return (
      <div className="inline-block px-3">
        <div className="w-96 h-120 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
          <figure>
            <img
              src="https://noneacademy.s3.eu-central-1.amazonaws.com/online-food-order.jpg"
              alt="Course Overview"
            />
          </figure>
          <div className="card-body">
            <p style={{ fontSize: "1.2rem" }} className="card-title">
              Online Food Delivery App
            </p>
            <RateBar rating={0} students={0} average={4.9} />
            <p className="text-left">
              Build Online Food delivery app using react native and nodejs{" "}
            </p>
            <p style={{ fontSize: 15, height: 20 }}>Jay</p>
            <div
              style={{
                display: "flex",
                fontSize: 16,
              }}
            >
              <p className="text-left">
                <b>Live Soon</b>
              </p>
            </div>

            <div className="card-actions justify-end">
              <button
                className="btn bg-blue-700 hover:bg-indigo-600 text-white"
                onClick={() => {
                  toast("You can not buy it before 15th April!", {
                    type: "warning",
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                  fill="#FFF"
                >
                  <path d="M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z" />
                </svg>
                Buy
              </button>
              <button
                className="btn bg-black text-white hover:bg-amber-600  hover:text-white"
                onClick={() => {
                  toast("Preview will be available at 15th April!", {
                    type: "warning",
                  });
                }}
              >
                Preview Course
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onAddToCart = async (courseId: string) => {
    try {
      if (user.UserId) {
        const status = await AddToCartApi(courseId, 1);
        if (status === 200) {
          dispatch(setCart(""));
          navigate("/cart");
        } else {
          localStorage.removeItem("token");
        }
      } else {
        dispatch(setCart(courseId));
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isCourseIsLive = (course: CourseModel) => {
    if (course.Active > 0) {
      return (
        <div className="flex fkex-col card-actions justify-around h-[60px] mt-4">
          <button
            className="btn bg-blue-700 hover:bg-indigo-600 text-white"
            onClick={() => onAddToCart(course.Id)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              fill="#FFF"
            >
              <path d="M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z" />
            </svg>
            Buy
          </button>
          <button
            className="btn bg-black text-white hover:bg-amber-600  hover:text-white"
            onClick={() => navigate(`/preview/${course.Id}`)}
          >
            Preview Course
          </button>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center flex-center h-[60px] mt-4">
          <button
            className="btn bg-warning rounded-full"
            onClick={() => {
              toast(
                "Keep check our discord channel notifications for announcement!",
                {
                  type: "warning",
                }
              );
            }}
          >
            Coming Soon
            <div className="badge badge-lg">
              <p className="text-sm">With Offer</p>
            </div>
          </button>
        </div>
      );
    }
  };

  const getCourses = () => {
    let courseNodes: ReactNode[] = [];
    courses.forEach((course) => {
      courseNodes.push(
        <div className="inline-block px-3">
          <div className="w-96 h-[520px] max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
            <figure>
              <img
                className="h-[180px]"
                src={course.ThumbnailImgUrl}
                alt="Course Overview"
              />
            </figure>
            <div className="card-body h-[340px]">
              <p style={{ fontSize: "1.2rem" }} className="card-title">
                {course.Title}
              </p>
              <RateBar rating={0} students={0} average={4.9} />
              <p className="line-clamp-2 text-left h-[60px] ">
                {course.Description}
              </p>
              <p style={{ fontSize: 15, height: 20 }}>{course.Author}</p>
              <div
                className="h-[60] mt-2"
                style={{
                  display: "flex",
                  fontSize: 16,
                }}
              >
                <p className="text-left">
                  <b>{course.Active > 0 ? `$${course.OfferPrice}` : "TBA"}</b>
                  <s>{course.Active > 0 && ` $${course.Price}`}</s>
                </p>
              </div>
              {checkIfPurchased(course.Id) ? (
                <div className="card-actions justify-center mt-2 h-[60px]">
                  <div className="badge bg-indigo-100 p-4 text-medium">
                    You already purchased!
                  </div>
                </div>
              ) : (
                isCourseIsLive(course)
              )}
            </div>
          </div>
        </div>
      );
    });

    return courseNodes;
  };

  return (
    <div style={{ margin: 30, marginTop: 50 }}>
      <div
        className="ml-10 pb-5 gap-2"
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
          fill="#C70039"
        >
          <path d="M480-80q-66 0-127.5-20.5T240-160l58-58q42 29 88 43.5t94 14.5q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480H80q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80ZM159-243l163-163 120 100 198-198v104h80v-240H480v80h104L438-414 318-514 117-313q11 23 19.5 37.5T159-243Zm321-237Z" />
        </svg>
        <p className="text-left text-2xl font-bold">Available Courses</p>
      </div>
      <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
        <div className="flex flex-nowrap lg:ml-10 md:ml-10 ml-10 ">
          {getCourses()}
        </div>
      </div>
    </div>
  );
};
