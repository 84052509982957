import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { VerifyIdentityApi } from "../../store/api/user.api";
import { recordEvent } from "../../utils/analytics";

export const VerifyIdentity = () => {
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);

  const email = searchParams.get("email");
  const code = searchParams.get("code");

  useEffect(() => {
    recordEvent("verify_identity-page", {
      event_type: "Visited",
      event_name: "visited",
      user_properties: {
        email,
      },
    });
    if (email && code) {
      onVerifyIdentity().then(() => {});
    }
  }, [email]);

  const onVerifyIdentity = async () => {
    try {
      const status = await VerifyIdentityApi(email as string, code as string);
      if (status === 200) {
        navigate("/");
      }
    } catch (err) {}
  };

  return (
    <div
      className="gap-10"
      style={{
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <progress className="progress w-56 bg-amber-500 text-lime-500"></progress>
      <p className="text-sm">Verifying your identity...</p>
    </div>
  );
};
