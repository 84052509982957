/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ComponentProps, useEffect, useState, FC } from "react";
import { Episode, Outlines } from "../../store/types";

interface PlayListProps {
  playlist: Outlines[];
  onWatchEpisode: Function;
  onOpen: Function;
  currentEpisode?: Episode;
}
export const PlayList: React.FC<PlayListProps> = ({
  playlist,
  onWatchEpisode,
  currentEpisode,
  onOpen,
}) => {
  const [open, setOpen] = React.useState(true);

  const handleOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
    onOpen(open);
  };

  const markWatch = (watched?: number) => {
    if (watched && watched > 0) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="28"
          viewBox="0 -960 960 960"
          width="28"
          fill="#65a30d"
        >
          <path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
        </svg>
      );
    }
    return <div style={{ width: 28, height: 28 }}></div>;
  };

  const onTapWatch = (episode: Episode) => {
    onWatchEpisode(episode);
  };

  const sortedEpisodes = (episodes: Episode[]): Episode[] => {
    let unsortedArray = [...episodes];
    const sortedEpisodes = unsortedArray.sort((a, b) =>
      a.Order < b.Order ? -1 : 1
    );
    return sortedEpisodes;
  };

  const secondsToMinutes = (totalSeconds?: number): string => {
    if (totalSeconds) {
      return `${(totalSeconds / 60).toFixed(2)}`.replace(".", ":");
    }
    return "00:00";
  };

  const handleDownload = (repo: string) => {
    window.open(repo, "__blank");
  };

  const playList = () => {
    return playlist.map((outline, index) => (
      <>
        <details
          className="collapse collapse-plus bg-base-200 mt-2"
          style={{
            borderRadius: 10,
          }}
        >
          <summary
            className="collapse-title font-medium"
            style={{
              height: 50,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <p className="text-medium">{outline.Title}</p>
          </summary>
          <div className="collapse-content">
            <ul role="list" className="divide-y divide-gray-100">
              {sortedEpisodes(outline.Episodes).map((episode, idx) => (
                <li
                  key={`${idx}-${episode.Id}`}
                  style={{
                    borderRadius: 12,
                    backgroundColor:
                      currentEpisode?.Id === episode.Id ? "#a3e635" : "#FFF",
                  }}
                  className="flex justify-between gap-x-6 bg-white"
                >
                  <a
                    className="flex min-w-0 gap-x-4 bg-white-100"
                    href="#"
                    onClick={() => onTapWatch(episode)}
                    style={{
                      width: "100%",
                      padding: 10,
                      paddingTop: 15,
                      paddingBottom: 15,
                    }}
                  >
                    <div
                      style={{
                        width: 25,
                        height: 25,
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path d="m380-300 280-180-280-180v360ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                      </svg>
                    </div>
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {episode.Title}
                    </p>
                  </a>
                  <div
                    style={{
                      display: "flex",
                      height: 60,
                      width: 120,
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <p className="text-xs">
                      {secondsToMinutes(episode.Duration)}
                    </p>
                    {markWatch(episode.Watched)}
                  </div>
                </li>
              ))}
              {outline.CourseRepo && (
                <button
                  onClick={() => handleDownload(outline.CourseRepo)}
                  className="btn-sm bg-blue-200 m-2 rounded-full gap-2"
                  style={{
                    width: 160,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="text-sm">Resource</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q17-72 85-137t145-65q33 0 56.5 23.5T520-716v242l64-62 56 56-160 160-160-160 56-56 64 62v-242q-76 14-118 73.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h480q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-48-22-89.5T600-680v-93q74 35 117 103.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H260Zm220-358Z" />
                  </svg>
                </button>
              )}
            </ul>
          </div>
        </details>
      </>
    ));
  };

  if (open) {
    return (
      <div
        style={{
          zIndex: 0,
          position: "fixed",
          display: "flex",
          top: 70,
          right: 0,
          width: 380,
          flexDirection: "column",
          paddingRight: 10,
          background: "transparent",
        }}
      >
        <div
          className="bg-lime-500 pl-2"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 10,
            marginBottom: 5,
          }}
        >
          <button onClick={handleOpen} className="btn btn-circle bg-lime-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              fill="#FFF"
            >
              <path d="M500-640v320l160-160-160-160ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm120-80v-560H200v560h120Zm80 0h360v-560H400v560Zm-80 0H200h120Z" />
            </svg>
          </button>

          <p className="text-left text-xl p-5 font-bold">Contents</p>
        </div>
        <div
          style={{
            height: "92vh",
            overflowY: "scroll",
            paddingBottom: 60,
          }}
        >
          <div
            className="bg-base-100"
            style={{
              width: 370,
              borderBottomRightRadius: 50,
              borderBottomLeftRadius: 50,
              background: "transparent",
            }}
          >
            {playList()}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="mt-5 mr-10">
        <button onClick={handleOpen} className="btn btn-circle bg-lime-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
            fill="#fff"
          >
            <path d="M460-320v-320L300-480l160 160ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm440-80h120v-560H640v560Zm-80 0v-560H200v560h360Zm80 0h120-120Z" />
          </svg>
        </button>
      </div>
    );
  }
};
