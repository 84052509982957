/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { AvailableCourses } from "../../components/AvailableCourses";
import { useEffect, useState } from "react";
import { FetchMeApi } from "../../store/api/user.api";
import { setCourses, userProfile } from "../../store/reducers";
import { CourseModel, UserModel } from "../../store/types";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { FetchPublicCoursesApi } from "../../store/api/course.api";
import { recordEvent } from "../../utils/analytics";

export const Landing = () => {
  useEffect(() => {
    recordEvent("landing-page", {
      event_type: "Visited",
      event_name: "visited",
      user_properties: {},
    });
  }, []);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const targetDate = new Date("2024-04-30");

  const calculateTimeLeft = (): {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } => {
    const difference = +targetDate - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft as {
      days: number;
      hours: number;
      minutes: number;
      seconds: number;
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setTimeLeft(calculateTimeLeft());
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // });

  const { courses } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    onFetchCourses();
    onFetchMe();
  }, []);

  const onFetchCourses = async () => {
    try {
      const response = await FetchPublicCoursesApi();
      if (response.data) {
        dispatch(setCourses(response.data as CourseModel[]));
      } else {
        localStorage.removeItem("token");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFetchMe = async () => {
    try {
      const response = await FetchMeApi();
      if (response.data) {
        dispatch(userProfile(response.data as UserModel));
      } else {
        localStorage.removeItem("token");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        minHeight: "80vhs",
      }}
    >
      <div className="hero py-10 bg-base-200" style={{ minHeight: 700 }}>
        <div className="hero-content flex-col lg:flex-row-reverse">
          <div className="mt-8">
            <h1 className="headline-font font-bold text-4xl mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              Learn with Real-life Projects and grab your dream job!
            </h1>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <div
                // className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
                style={{
                  flex: 5,
                  height: "100%",
                  borderWidth: "1px",
                  borderRadius: 10,
                }}
              >
                <img
                  src="https://noneacademy.s3.eu-central-1.amazonaws.com/hero.jpg"
                  className="object-contain rounded-lg shadow-2xl h-120 w-120"
                />
              </div>
              <div
                style={{
                  flex: 6,
                  padding: 10,
                }}
              >
                <ul className="list-outside list-disc py-10">
                  <li className="text-left text-xl py-2">
                    The best way to become an expert on something you need to
                    experience in real life.
                  </li>
                  <li className="text-left text-xl py-2">
                    Try CodEpisodes to gain all expertise through our crafted
                    courses that will give you all experience what you need to
                    land your career in the next level.
                  </li>
                  <li className="text-left text-xl py-2">
                    Courses are designed by Professional Engineers, Experienced
                    Developers, who has deep knowledge and experience on build
                    scalable applications deployed in production environment.
                  </li>
                  <li className="text-left text-xl py-2">
                    Course contents are not a ToDo or simple CRUD operations
                    it's a practical experience of someones that learned through
                    his professional career path.
                  </li>
                </ul>
                <button
                  className="btn bg-blue-700 text-white hover:bg-indigo-600"
                  onClick={() => {
                    recordEvent("landing-page", {
                      event_type: "Button_Clicked",
                      event_name: "get_started",
                      user_properties: {},
                    });
                    navigate("/signup");
                  }}
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AvailableCourses
        courses={Array.isArray(courses) ? courses : []}
        yourCourses={[]}
      />
    </div>
  );
};

/*
// Timer
 <div
  style={{
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  }}
  className="mb-20 mt-10"
>
  <p className="text-4xl m-5">We will Live soon!</p>
  <div className="grid grid-flow-col gap-5 text-center auto-cols-max">
    <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
      <span className="countdown font-mono text-5xl">
        {timeLeft.days}
      </span>
      days
    </div>
    <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
      <span className="countdown font-mono text-5xl">
        {timeLeft.hours}
      </span>
      hours
    </div>
    <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
      <span className="countdown font-mono text-5xl">
        {timeLeft.minutes}
      </span>
      min
    </div>
    <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
      <span className="countdown font-mono text-5xl">
        {timeLeft.seconds}
      </span>
      sec
    </div>
  </div>
</div>
*/
