import { useEffect, useState } from "react";
import { VerifyPaymentApi } from "../../store/api/course.api";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";

export const Verify = () => {
  const navigate = useNavigate();

  const [finalized, setFinalized] = useState(false);
  const [onError, setOnError] = useState(false);

  const onFinalizePayment = async () => {
    try {
      const status = await VerifyPaymentApi();
      if (status === 200) {
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      } else if (status === 403) {
        // show error
      } else {
        setOnError(true);
      }
    } catch (error) {
      console.log(error);
    }
    setFinalized(true);
  };

  useEffect(() => {
    onFinalizePayment();
  }, []);

  // call payment confirmation call
  if (!finalized) {
    return (
      <div
        className="flex justify-center items-center flex-col"
        style={{ height: "80vh" }}
      >
        <span
          className="loading loading-dots loading-lg"
          style={{ color: "red" }}
        ></span>
        <p className="text-xl">Loading Purchase details ...</p>
      </div>
    );
  } else {
    if (onError) {
      return (
        <div
          className="flex justify-center items-center flex-col"
          style={{ height: "80vh" }}
        >
          <p className="text-xl">
            Your purchase was not completed due to some issues...
          </p>
          <p className="text-medium mt-5">
            Click on retry button to complete the purchase!
          </p>
          <button
            className="btn bg-black text-white hover:bg-indigo-600 hover:text-white mt-10"
            onClick={() => onFinalizePayment()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              fill="#FFF"
            >
              <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" />
            </svg>
            retry
          </button>
        </div>
      );
    } else {
      return (
        <div
          className="flex justify-center items-center flex-col"
          style={{ height: "80vh" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="80"
            viewBox="0 -960 960 960"
            width="80"
            fill="#5700C7"
          >
            <path d="m80-80 200-560 360 360L80-80Zm132-132 282-100-182-182-100 282Zm370-246-42-42 224-224q32-32 77-32t77 32l24 24-42 42-24-24q-14-14-35-14t-35 14L582-458ZM422-618l-42-42 24-24q14-14 14-34t-14-34l-26-26 42-42 26 26q32 32 32 76t-32 76l-24 24Zm80 80-42-42 144-144q14-14 14-35t-14-35l-64-64 42-42 64 64q32 32 32 77t-32 77L502-538Zm160 160-42-42 64-64q32-32 77-32t77 32l64 64-42 42-64-64q-14-14-35-14t-35 14l-64 64ZM212-212Z" />
          </svg>
          <p className="text-xl mt-5">
            Congratulations for your new course ...
          </p>
        </div>
      );
    }
  }
};
