/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { useEffect } from "react";
import { setLogout } from "../../store/reducers";

export const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    localStorage.clear();
    dispatch(setLogout({}));
    navigate("/");
  }, []);

  return (
    <div
      className="flex justify-center items-center flex-col"
      style={{ height: 600 }}
    >
      <span className="loading loading-dots loading-lg"></span>
      <p className="text-xl">Logging out...</p>
    </div>
  );
};
