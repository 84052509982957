import { useEffect } from "react";
import { recordEvent } from "../../utils/analytics";

export const TermsOfUse = () => {
  useEffect(() => {
    recordEvent("terms_n_conditions-page", {
      event_type: "Visited",
      event_name: "visited",
      user_properties: {},
    });
  }, []);

  return (
    <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
        <p className="text-4xl">Terms and Conditions of Use</p>

        <figure className="mt-10">
          <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
            <p className="text-justify">
              These Terms and Conditions of Use ("Terms") govern your access to
              and use of Code Episodes operated by us. By accessing or using the
              Website, you agree to be bound by these Terms. If you do not agree
              to these Terms, please refrain from using the Website. The purpose
              of this website is to provide accessible and user-friendly
              resources to teach coding to individuals of all skill levels,
              making the learning process as smooth and efficient as possible.
              We aim to break down complex concepts into simple,
              easy-to-understand explanations, supplemented with practical
              examples and interactive exercises. Key features of our website
              include:
            </p>
          </blockquote>
          <ul className="list-disc mt-10 text-justify">
            <li className="mt-5">
              Structured Learning Paths: We offer curated learning paths
              tailored to different skill levels and programming languages.
              Whether you're a complete beginner or an experienced coder looking
              to expand your knowledge, our pathways will guide you through the
              learning process step by step.
            </li>
            <li className="mt-5">
              Interactive Tutorials: Our tutorials are designed to be engaging
              and interactive, allowing users to learn by doing. We provide
              hands-on coding exercises and challenges to reinforce concepts and
              promote active learning.
            </li>
            <li className="mt-5">
              Real-world Projects: We believe in learning by building, so we
              provide a variety of real-world projects that allow users to apply
              their coding skills in practical contexts. These projects range
              from simple exercises to more advanced applications, ensuring
              learners gain practical experience along the way.
            </li>
            <li className="mt-5">
              Supportive Community: Learning to code can be challenging, but
              it's easier when you have support. Our website fosters a
              supportive online community where learners can connect with peers,
              ask questions, and share their progress. We also provide access to
              mentors and experts who can offer guidance and assistance.
            </li>
            <li className="mt-5">
              Continuous Feedback and Progress Tracking: We believe in the
              importance of feedback for learning, so we provide tools for users
              to track their progress and receive feedback on their code. Our
              platform offers automated code grading and personalized
              recommendations to help users identify areas for improvement and
              continue to grow their skills.
            </li>
          </ul>
          <ul className="mt-20">
            <p className="text-2xl mt-5 mb-5">User Accounts</p>
            <p>
              You may be required to create an account and provide certain
              information about yourself in order to access certain features of
              the Website. You are responsible for maintaining the
              confidentiality of your account credentials and for all activities
              that occur under your account. You agree to provide accurate,
              current, and complete information during the registration process
              and to update such information to keep it accurate, current, and
              complete.
            </p>
            <p className="text-2xl mt-5 mb-5">Payment and Billing</p>
            <p>
              Payment for services or products offered on the Website must be
              made using one of the accepted payment methods as specified on the
              Website. Prices for services or products are subject to change
              without notice. CodEpisodes reserves the right to modify or
              discontinue any service or product without prior notice. All
              payments are final and non-refundable unless otherwise stated in
              the refund policy.
            </p>
            <p className="text-2xl mt-5 mb-5">Refunds and Cancellations</p>
            <p>
              Refund policies vary depending on the type of service or product
              purchased. Please refer to the specific refund policy associated
              with the service or product you have purchased. Subscriptions may
              be cancelled at any time, but no refunds will be provided for the
              remaining subscription period.
            </p>

            <p className="text-2xl mt-5 mb-5">Intellectual Property Rights</p>
            <p>
              All content and materials available on the Website, including but
              not limited to text, graphics, logos, images, and software, are
              the property of the CodEpisodes or its licensors and are protected
              by copyright, trademark, and other intellectual property laws. You
              may not modify, reproduce, distribute, transmit, display, perform,
              or create derivative works from any content or materials obtained
              from the Website without the prior written consent of the
              CodEpisodes .
            </p>
            <p className="text-2xl mt-5 mb-5">Content Usage and Licensing</p>
            <p>
              At CodEpisode, we strive to provide a seamless learning experience
              for our users while ensuring the integrity of our educational
              content. To maintain the quality of our services, we have
              established the following usage guidelines:
              <ul className="list-disc mt-10 text-justify mt-4">
                <li className=" m-5 mt-2">
                  Personal Use Only: Users may access and utilize the
                  educational content available on the Website strictly for
                  personal, non-commercial purposes. This includes learning,
                  practicing, and enhancing skills relevant to their individual
                  goals and aspirations.
                </li>
                <li className=" m-5 mt-2">
                  Non-Transferable Rights: Users are prohibited from
                  sublicensing, transferring, or assigning their rights to
                  access or use the content or materials on the Website to any
                  third party. Your access to our courses is intended solely for
                  your own benefit and educational advancement.
                </li>
                <li className=" m-5 mt-2">
                  Account Integrity: We closely monitor account activity to
                  ensure compliance with our usage policy. If we detect any
                  unauthorized sharing or use of accounts by multiple users, we
                  reserve the right to revoke access to purchased courses. Users
                  found in violation of this policy may lose access to all
                  purchased courses without refund.
                </li>
              </ul>
              We appreciate your cooperation in adhering to these guidelines, as
              they are essential for maintaining the quality and integrity of
              our platform. If you have any questions or require further
              clarification regarding our usage policy, please don't hesitate to
              contact our support team. We're here to assist you every step of
              the way.
            </p>
          </ul>
        </figure>
      </div>
    </section>
  );
};
