import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ChangePassword } from "../../store/api/user.api";
import { useNavigate } from "react-router-dom";
import { setLogout } from "../../store/reducers";
import { UserModel } from "../../store/types";
import { recordEvent } from "../../utils/analytics";

export const Account = () => {
  useEffect(() => {
    recordEvent("account-page", {
      event_type: "Visited",
      event_name: "visited",
      user_properties: {},
    });
  }, []);

  const { user } = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();

  const [password, setPassword] = useState("");
  const [changePwd, setChangePwd] = useState(false);
  const navigate = useNavigate();

  const userFullName = () => {
    if (user) {
      return `${user.FirstName} ${user.LastName}`;
    }
    return "";
  };

  const userEmail = () => {
    if (user) {
      return user.Email;
    }
    return "";
  };

  const userPhone = () => {
    if (user) {
      return user.Phone;
    }
    return "";
  };

  const userAddress = () => {
    if (user.Address) {
      return `${user.Address.street},
      ${user.Address.state},
      ${user.Address.country}
              `;
    }
    return "";
  };

  const onTapChangePassword = async () => {
    // TODO: alert
    if (password.length < 6) return;
    try {
      const status = await ChangePassword(password);
      if (status === 200) {
        localStorage.clear();
        dispatch(setLogout({} as UserModel));
        navigate("/login");
      }
    } catch (error) {}
  };

  return (
    <div
      style={{
        height: "80vh",
      }}
      className="m-20 bg-base-100 border-round-all p-10"
    >
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900 mb-20">
          Your Profile
        </h3>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Full name
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {userFullName()}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Email address
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {userEmail()}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Password
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <div className="mt-2">
                {!changePwd ? (
                  <button
                    onClick={() => setChangePwd(true)}
                    className="btn bg-lime-500 rounded-full"
                  >
                    Change Password
                  </button>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      required
                      placeholder="Your new password"
                      className="input max-w-xs rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-4"
                    />
                    <button
                      onClick={() => setChangePwd(false)}
                      className="btn bg-orange-300 rounded-full text-white hover:bg-lime-600 mr-4"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                      </svg>
                    </button>
                    <button
                      onClick={() => onTapChangePassword()}
                      className="btn bg-lime-500 rounded-full text-white hover:bg-lime-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Phone
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {userPhone()}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Address
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {userAddress()}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};
