/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect, useState } from "react";
import { onResetPassword, onSetNewPassword } from "../../store/api/user.api";
import { recordEvent } from "../../utils/analytics";
import { toast } from "react-toastify";

export const SetNewPassword = () => {
  useEffect(() => {
    recordEvent("set_new_password-page", {
      event_type: "Visited",
      event_name: "visited",
      user_properties: {},
    });
  }, []);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const searchParams = new URLSearchParams(window.location.search);

  const email = searchParams.get("email");
  const code = searchParams.get("code");
  const [password, setPassword] = useState("");

  // TODO: after sandgrid template
  const onTapLogin = async () => {
    if (!email && !code && password === "") {
      toast("Please enter email address and password!", {
        type: "error",
      });
      return;
    }

    try {
      if (email) {
        const status = await onSetNewPassword(email, password, code as string);

        if (status === 200) {
          localStorage.clear();
          navigate("/login");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8"
      style={{
        height: "80vh",
      }}
    >
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                value={email || ""}
                type="email"
                disabled
                required
                placeholder="Your email"
                className="input w-full max-w-xs rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between max-w-xs">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                New Password
              </label>
            </div>
            <div className="mt-2">
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="text"
                required
                placeholder="Your Password"
                autoComplete="none"
                className="input w-full max-w-xs rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              onClick={onTapLogin}
              className="btn btn-wide rounded-full w-full max-w-xs bg-lime-500 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
