import React from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

interface CheckoutFormProps {
  onHandleReturn: Function;
}

export const CheckoutForm: React.FC<CheckoutFormProps> = ({
  onHandleReturn,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const response = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });
    onHandleReturn(response);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="flex justify-center items-center flex-col">
        <button
          className="btn btn-wide mt-10 bg-black text-white border-none"
          onClick={() => {}}
          disabled={!stripe}
        >
          Pay
        </button>
      </div>
    </form>
  );
};
