// // Dev
// export const PUBLIC_BASE_URL = "http://localhost:9000";
// export const BASE_URL = "http://localhost:9000/admin";
// export const CLOUD_FRONT_URL =
//   "https://glass-test-bucket.s3.us-east-2.amazonaws.com";

// // Production
// export const BASE_URL = "https://api.glashomes.com/admin";
// export const PUBLIC_BASE_URL = "https://api.glashomes.com";
// export const CLOUD_FRONT_URL = "https://glashomes.s3.us-east-2.amazonaws.com";

// // // Process.env
export const BASE_URL = process.env.REACT_APP_BASE_URL;

// // Evan's urls
// export const BASE_URL = "https://api.glashomes.com/admin";
// export const PUBLIC_BASE_URL = "https://api.glashomes.com";
// export const CLOUD_FRONT_URL = process.env.REACT_APP_CLOUD_FRONT_URL;
