import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { CourseModel } from "../../store/types";

interface YourCoursesProps {
  courses: CourseModel[];
}

export const YourCourses: React.FC<YourCoursesProps> = ({ courses }) => {
  const navigate = useNavigate();

  const myCourses = () => {
    if (courses.length < 1) {
      return (
        <div>
          <div className="card w-96 bg-base-100 shadow-xl">
            <div className="card-body">
              <h2 className="card-title">
                Enroll a Course Now!
                <div className="badge badge-warning">Offer Available</div>
              </h2>
              <p>
                Don't stay behind! always stay updated with hot pro skills for
                your next level of the career goal?
              </p>
              <p className="text-sm">
                We have good contents on the below topics
              </p>
              <div className="card-actions justify-end">
                <div className="badge badge-outline">Backend</div>
                <div className="badge badge-outline">Go</div>
                <div className="badge badge-outline">Node.Js</div>
                <div className="badge badge-outline">DevOps</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    let courseNodes: ReactNode[] = [];
    courses.forEach((course) => {
      courseNodes.push(
        <div className="inline-block px-3">
          <div
            className="card w-96 bg-base-100 shadow-xl image-full"
            style={{
              height: 280,
            }}
          >
            <figure>
              <img src={course.ThumbnailImgUrl} alt="Course Overview" />
            </figure>
            <div className="card-body">
              <h2 className="card-title text-white">{course.Title}</h2>
              <p className="mt-1 line-clamp-2 text-left h-[60px] text-sm text-white">
                {course.Description}
              </p>
              <div className="card-actions space-around">
                {/* <p className="text-left text-white">Progress:</p> */}
                <button
                  className="btn bg-amber-500 text-black border-none hover:bg-white"
                  onClick={() => navigate(`/watch/${course.Id}`)}
                >
                  Watch
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    fill="#000"
                  >
                    <path d="m380-300 280-180-280-180v360ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return courseNodes;
  };

  return (
    <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
      <div className="flex flex-nowrap lg:ml-10 md:ml-10 ml-10 ">
        {myCourses()}
      </div>
    </div>
  );
};
