import { useEffect } from "react";
import "./App.css";
import * as amplitude from "@amplitude/analytics-browser";

import { RouterHandler } from "./pages/RouterHandler";
import { Providers } from "./store/Provider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    amplitude.init("d343cc08b1076262025397e1bdd690bb");
  }, []);
  return (
    <Providers>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <RouterHandler />
    </Providers>
  );
}

export default App;
