import { useEffect } from "react";
import { recordEvent } from "../../utils/analytics";

export const Cancellation = () => {
  useEffect(() => {
    recordEvent("cancellation-page", {
      event_type: "Visited",
      event_name: "visited",
      user_properties: {},
    });
  }, []);

  return (
    <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
        <p className="text-4xl">Payment & Cancellation Policy</p>

        <figure className="mt-10">
          <ul className="mt-20">
            <p className="text-2xl mt-5 mb-5">Payment and Billing</p>
            <p>
              Payment for services or products offered on the website must be
              made using one of the accepted payment methods as specified below
              in the payment method list. Prices for services or products are
              subject to change without notice based on promotion strategy.
              CodEpisodes reserves the right to modify or discontinue any
              service or product without prior notice. All payments are final
              and non-refundable unless otherwise stated in the refund policy
              for certain cases based cancellation policy outlines.
              <p className="font-bold m-5">Accepted Payment Methods:</p>
              <ul className="list-disc mt-10 text-justify mt-4">
                <li className=" m-5 mt-2">
                  We accept payments via credit/debit cards, Net banking and
                  other methods as specified at the time of checkout as per our
                  Payment Gateway provider and availability based on country &
                  region.
                </li>
              </ul>
              <p className="font-bold m-5">Payment Processing:</p>
              <ul className="list-disc mt-10 text-justify mt-4">
                <li className=" m-5 mt-2">
                  Payments are processed securely through our payment gateway
                  provider, ensuring the confidentiality and integrity of your
                  financial information.
                </li>
              </ul>
              <p className="font-bold m-5">Payment Confirmation:</p>
              <ul className="list-disc mt-10 text-justify mt-4">
                <li className=" m-5 mt-2">
                  Upon successful payment, you will receive a confirmation email
                  with details of your order and payment transaction.
                </li>
              </ul>
              <p className="font-bold m-5">Payment Issues:</p>
              <ul className="list-disc mt-10 text-justify mt-4">
                <li className=" m-5 mt-2">
                  If you encounter any issues during the payment process, please
                  contact our customer support team at supprt@codepisodes.com
                  for assistance.
                </li>
              </ul>
            </p>
            <p className="text-2xl mt-5 mb-5">Refunds and Cancellations</p>
            <p>
              Refund policies vary depending on the type of service or product
              purchased. Please refer to the specific refund policy associated
              with the service or product you have purchased. Subscriptions may
              be cancelled at any time, but no refunds will be provided for the
              remaining subscription period.
            </p>
            <p className="text-2xl mt-5 mb-5">Refund & Cancellation Policy</p>
            <p>
              At CodEpisode, we understand that sometimes mistakes happen.
              That's why we offer a hassle-free refund process if you
              accidentally purchase a course and haven't started watching it
              yet.
              <p className="font-bold m-5">Order Cancellation:</p>
              <ul className="list-disc mt-10 text-justify mt-4">
                <li className=" m-5 mt-2">
                  Orders can be canceled within 3 hours of placing the order in
                  case, if you have not started watching the course you will get
                  full refund.
                </li>
                <li className=" m-5 mt-2">
                  To cancel an order, please write to us on at
                  support@codepisodes.com by providing your order id and proof
                  of payment successful email.
                </li>
                <li className=" m-5 mt-2">
                  Orders canceled within the specified time frame above will
                  receive the full refund amount.
                </li>
                <li className=" m-5 mt-2">
                  Tax and Application Fee is non refundable to cover
                  infrastructure and operating cost.
                </li>
              </ul>
              <p className="font-bold m-5">Here's how Refund works:</p>
              <ul className="list-disc mt-10 text-justify mt-4">
                <li className=" m-5 mt-2">
                  Eligibility Verification: Once you reach out to us regarding
                  the accidental purchase, we'll verify your activity and
                  confirm that you haven't started watching the course.
                </li>
                <li className=" m-5 mt-2">
                  Refund Initiation: Upon confirming your eligibility, we'll
                  initiate the refund process through the original payment
                  method used for the purchase.
                </li>
                <li className=" m-5 mt-2">
                  Exclusions: Please note that taxes and application fees are
                  non-refundable when it comes to process a refund.
                </li>
                <li className=" m-5 mt-2">
                  We reserve the right to modify or update this cancellation
                  policy at any time. Any changes will be effective immediately
                  upon posting on our website. It is your responsibility to
                  review this policy periodically for updates.
                </li>
              </ul>
            </p>
          </ul>
        </figure>
      </div>
    </section>
  );
};
