/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect, useState } from "react";
import { FetchMeApi, LoginApi } from "../../store/api/user.api";
import { setCourses, userLogin, userProfile } from "../../store/reducers";
import { CourseModel, ResponseModel, UserModel } from "../../store/types";
import { FetchPublicCoursesApi } from "../../store/api/course.api";
import { toast } from "react-toastify";
import { recordEvent } from "../../utils/analytics";

export const Login = () => {
  useEffect(() => {
    recordEvent("login-page", {
      event_type: "Visited",
      event_name: "visited",
      user_properties: {},
    });
  }, []);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { cart } = useAppSelector((state) => state.userReducer);

  const onTapLogin = async () => {
    if (email === "" || password === "") {
      toast("Please enter email address and password!", {
        type: "error",
      });
      return;
    }

    recordEvent("login-page", {
      event_type: "Button_Clicked",
      event_name: "login",
      user_properties: {
        email: email,
      },
    });

    try {
      const data = await LoginApi(email, password);
      const userData = data as ResponseModel;
      if (userData.data.token) {
        localStorage.setItem("token", userData.data.token);
        dispatch(userLogin(userData.data as UserModel));

        await onFetchMe();
        await onFetchCourses();

        if (Array.isArray(cart) && cart.length > 0) {
          navigate("/cart");
          return;
        }
        navigate("/dashboard");
      } else {
        localStorage.removeItem("token");
      }
    } catch (error) {
      const err = error as Error;
      toast(err.message, {
        type: "error",
      });
    }
  };

  const onFetchMe = async () => {
    try {
      const response = await FetchMeApi();
      if (response.data) {
        dispatch(userProfile(response.data as UserModel));
      } else {
        localStorage.removeItem("token");
      }
    } catch (error) {}
  };

  const onFetchCourses = async () => {
    try {
      const response = await FetchPublicCoursesApi();
      if (response.data) {
        dispatch(setCourses(response.data as CourseModel[]));
      } else {
        localStorage.removeItem("token");
      }
    } catch (error) {}
  };

  return (
    <div
      className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8"
      style={{
        height: "80vh",
      }}
    >
      <div
        className="sm:mx-auto sm:w-full sm:max-w-sm"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required
                placeholder="Your email"
                className="input w-full max-w-xs  border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between max-w-xs">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                required
                placeholder="Your Password"
                autoComplete="none"
                className="input w-full max-w-xs border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="text-sm ">
            <button
              onClick={() => navigate("/reset")}
              className="font-semibold text-blue-700 hover:text-indigo-500 "
            >
              Forgot password?
            </button>
          </div>

          <div>
            <button
              onClick={onTapLogin}
              className="btn btn-wide w-full max-w-xs bg-blue-700 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
            >
              Sign in
            </button>
          </div>
        </div>
        <p className="mt-10 text-center text-sm text-gray-500">
          Not a member ?
          <button
            onClick={() => navigate("/signup")}
            className="font-semibold leading-6 text-blue-700 hover:text-indigo-500 ml-2"
          >
            Join Code Episodes for Free
          </button>
        </p>
      </div>
    </div>
  );
};
