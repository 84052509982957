import axios, { AxiosResponse } from "axios";
import { ResponseModel } from "../types";
import { BASE_URL } from "./appConst";
import { axiosAuth } from "./common";

export const FetchPublicCoursesApi = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/courses?limit=100&offset=0`);
    return response.data as ResponseModel;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};

export const FetchCourseApi = async (id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/courses/${id}`);
    return response.data as ResponseModel;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};

export const AddToCartApi = async (id: string, qty: number) => {
  try {
    const axios = axiosAuth();
    const response = await axios.post(`${BASE_URL}/cart`, {
      id,
      qty,
    });
    return response.status;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};

export const MakePaymentApi = async () => {
  try {
    const axios = axiosAuth();
    const response = await axios.post(`${BASE_URL}/payment`);
    return response.data.data;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};

export const VerifyPaymentApi = async () => {
  try {
    const axios = axiosAuth();
    const response = await axios.post(`${BASE_URL}/verify`);
    return response.status;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};

export const WatchCourseById = async (id: string): Promise<AxiosResponse> => {
  try {
    const api = axiosAuth();
    const response = await api.get(`${BASE_URL}/courses/watch/${id}`);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const FetchWatchUrl = async (courseId: string, id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/watch/${courseId}/${id}`);
    return response.data as ResponseModel;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};

export const MarkWatched = async (id: string, episode: string) => {
  try {
    const api = axiosAuth();
    const response = await api.patch(`${BASE_URL}/watch/${id}/${episode}`);
    return response.data as ResponseModel;
  } catch (error) {
    return {
      msg: "error occurred",
    };
  }
};
