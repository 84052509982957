/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
  PaymentIntentResult,
} from "@stripe/stripe-js";
import { CheckoutForm } from "./PaymentForm";
import { useAppSelector } from "../../store/hooks";
import { CartTotal } from "../../utils/cart.utils";
import { useNavigate } from "react-router-dom";
import { MakePaymentApi, VerifyPaymentApi } from "../../store/api/course.api";
import GA from "react-ga4";
import { toast } from "react-toastify";

const pk =
  "pk_live_51P2spaHV4CtDYt8Iv1CRgHQSiYvAIAvWZpBXx65WHNXONC9GwSuQo3bV1MEMJymQpLz2oEiUBJGhkSvDAxJra5bx00O0QIh525";

export const Payment = () => {
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.userReducer);

  const [clientSecret, setClientSecret] = useState();
  const [finalizing, setFinalizing] = useState(false);

  useEffect(() => {
    makePayment();
    GA.send({
      hitType: "pageview",
      page: "/",
      title: "Payment Page",
    });
  }, []);

  const makePayment = async () => {
    try {
      const data = await MakePaymentApi();
      if (data.secret) {
        console.log(data.secret);
        setClientSecret(data.secret);
      } else {
        toast("Payment is not possible at this moment!", {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
      toast("Payment is not going through! contact customer support", {
        type: "error",
      });
    }
  };

  const stripePromise = () => {
    return loadStripe(pk);
  };

  const appearance: Appearance = {
    theme: "flat",
    variables: {
      fontWeightNormal: "500",
      borderRadius: "2px",
      colorBackground: "white",
      colorPrimary: "#DF1B41",
      colorPrimaryText: "white",
      spacingGridRow: "15px",
    },
    rules: {
      ".Label": {
        marginBottom: "6px",
      },
      ".Tab, .Input, .Block": {
        boxShadow: "0px 3px 10px rgba(18, 42, 66, 0.08)",
        padding: "12px",
      },
    },
  };

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  const onHandleReturnUrl = async (response: PaymentIntentResult) => {
    const { error, paymentIntent } = response;

    if (paymentIntent) {
      if (paymentIntent.status === "succeeded") {
        navigate("/verify");
      }
    } else {
      console.log(error);
      toast(
        "error on payment verification! Kindly contact our customer support",
        {
          type: "error",
        }
      );
    }
  };

  const isCartExist = user && Array.isArray(user.Cart) && user.Cart.length > 0;

  const total = () => {
    return CartTotal(user.amount);
  };

  const displayCartItems = () => {
    if (isCartExist) {
      return (
        <div className="mt-5">
          <div className="flow-root">
            <div className="flex justify-start flex-row">
              <button
                onClick={() => navigate("/cart")}
                type="button"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Modify to Cart
              </button>
            </div>
            <ul role="list" className="my-6 divide-y divide-gray-200 mt-2">
              {user &&
                Array.isArray(user.Cart) &&
                user.Cart.map((item) => (
                  <li className="flex py-6">
                    <div className="h-24 w-48 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                      <img
                        src={item.ThumbnailUrl}
                        alt="course image"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>

                    <div className="ml-4 flex flex-1 flex-col">
                      <div>
                        <div className="flex justify-between text-base font-medium text-gray-900">
                          <p className="font-medium">{item.Title}</p>
                          <p className="ml-4">${item.OfferPrice.toFixed(2)}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
            <div className="mt-4">
              {isCartExist && (
                <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                  <div className="flex justify-between text-sm text-gray-900">
                    <p>Tax & Application Fee</p>
                    <p>{`$${(total().tax + total().fee).toFixed(2)}`}</p>
                  </div>
                  <div className="flex justify-between text-base font-medium text-gray-900 mt-2">
                    <p>Total Payable</p>
                    <p>{`$${total().total.toFixed(2)}`}</p>
                  </div>
                  <p className="mt-0.5 text-sm text-gray-500 mt-2">
                    * Tax and Application Fee will be not refundable. Check
                    Terms of use
                  </p>
                  <div
                    className="mt-6"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  if (pk && clientSecret) {
    return (
      <div
        style={{ height: "80vh" }}
        className="flex justify-center items-center flex-col mt-10"
      >
        <p className="text-3xl mt-10 mb-2 text-center">
          Complete the payment to continue!
        </p>

        <div
          className="md:columns-2 sm:columns-1 p-10 gap-20"
          style={{ minHeight: 600 }}
        >
          <div className="p-20">{displayCartItems()}</div>
          <div className="p-2 pt-10 " style={{ width: 400 }}>
            <Elements options={options} stripe={stripePromise()}>
              <CheckoutForm onHandleReturn={onHandleReturnUrl} />
            </Elements>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="flex justify-center items-center flex-col"
        style={{ height: "80vh" }}
      >
        <span
          className="loading loading-dots loading-lg"
          style={{ color: "red" }}
        ></span>
        <p className="text-xl">Preparing Payment...</p>
      </div>
    );
  }
};
