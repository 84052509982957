import { useEffect } from "react";
import logo_with_title from "../../img/logo_with_title.png";
import { recordEvent } from "../../utils/analytics";

export const AboutUs = () => {
  useEffect(() => {
    recordEvent("about-page", {
      event_type: "Visited",
      event_name: "visited",
      user_properties: {},
    });
  }, []);

  return (
    <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
        <p className="text-4xl">CodEpisodes: Learn and Earn More</p>

        <figure className="mt-10">
          <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
            <p className="text-justify">
              Code Episodes pronounce as CodEpisodes stands as the premier
              online learning platform tailored for IT Engineers seeking to
              elevate their proficiency in technology and professional skills.
              With a curated selection of courses, CodEpisodes empowers learners
              to master coding across various programming languages, enabling
              them to excel in their careers and projects. Dive into our
              comprehensive curriculum and embark on a journey towards expertise
              with CodEpisodes.
            </p>
          </blockquote>
          <ul className="list-disc mt-10 text-justify">
            <li className="mt-5">
              Expertly Crafted Courses: Gain access to meticulously designed
              courses led by industry experts, ensuring a high-quality learning
              experience.
            </li>
            <li className="mt-5">
              Language Coverage: Java Script, Typescript, Go,Rust,Python, SQL,
              NoSQL. CodEpisode offers a wide array of courses covering popular
              programming ecosystems, catering to diverse learning preferences.
            </li>
            <li className="mt-5">
              Practical Learning: Immerse yourself in hands-on, practical
              exercises and projects, allowing you to apply your newfound
              knowledge in real-world scenarios.
            </li>
            <li className="mt-5">
              Flexible Learning Experience: Enjoy the flexibility of learning at
              your own pace, with on-demand access to course materials and
              resources available 24/7.
            </li>
            <li className="mt-5">
              Professional Development: Beyond coding proficiency, CodEpisode
              equips learners with essential professional skills, including
              project management, communication, and problem-solving.
            </li>
            <li className="mt-5">
              Supportive Community: Connect with like-minded IT professionals,
              instructors, and mentors through our vibrant community forums,
              fostering collaboration and networking opportunities.
            </li>
            <li className="mt-5">
              Personalized Learning Pathways: Tailor your learning journey with
              personalized recommendations and progress tracking, ensuring
              targeted skill development aligned with your goals.
            </li>
          </ul>
        </figure>
      </div>
      <div className="mt-20 mx-auto max-w-2xl lg:max-w-4xl">
        <img className="mx-auto h-12" src={logo_with_title} alt="" />
        <figure className="mt-10">
          <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
            <p>
              “Join CodEpisodes today and unlock your potential in mastering
              coding and professional skills. Let's code your success together!”
            </p>
          </blockquote>
        </figure>
      </div>
    </section>
  );
};
