/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AddToCartApi, FetchCourseApi } from "../../store/api/course.api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCart, userProfile } from "../../store/reducers";
import { AddressInput, UserModel } from "../../store/types";
import { FetchMeApi, UpdateAddressApi } from "../../store/api/user.api";
import { CartTotal } from "../../utils/cart.utils";
import { toast } from "react-toastify";
import { recordEvent } from "../../utils/analytics";

export const Cart = () => {
  useEffect(() => {
    recordEvent("cart-page", {
      event_type: "Visited",
      event_name: "visited",
      user_properties: {},
    });
  }, []);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [street, setStreet] = useState("");
  const [stateName, setStateName] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");

  const { user, cart } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    onPreCart();
  }, []);

  useEffect(() => {
    if (user.Address) {
      setStreet(user.Address.street);
      setCountry(user.Address.country);
      setStateName(user.Address.state);
      setPhone(user.Phone || "");
    }
  }, [user.Address]);

  const onPreCart = async () => {
    try {
      if (user.token) {
        if (Array.isArray(cart) && cart.length > 0) {
          const status = await AddToCartApi(cart[0], 1);
          if (status === 200) {
            dispatch(setCart(""));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    await onFetchMe();
  };

  const onFetchMe = async () => {
    try {
      const response = await FetchMeApi();
      if (response.data) {
        dispatch(userProfile(response.data as UserModel));
      } else {
        localStorage.removeItem("token");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const total = () => {
    return CartTotal(user.amount);
  };

  const onRemoveFromCart = async (courseId: string) => {
    try {
      if (user.UserId) {
        const status = await AddToCartApi(courseId, 0);
        if (status === 200) {
          await onFetchMe();
        } else {
          toast("error on removing from cart!", {
            type: "error",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSaveAddress = async () => {
    if (street === "") {
      toast("street address is required!", {
        type: "error",
      });
      return;
    }

    if (stateName === "") {
      toast("State Name is required!", {
        type: "error",
      });
      return;
    }

    if (country === "") {
      toast("Country is required!", {
        type: "error",
      });
      return;
    }

    if (phone === "") {
      toast("Phone Number is required!", {
        type: "error",
      });
      return;
    }

    try {
      if (street) {
        const address: AddressInput = {
          street,
          country,
          state: stateName,
          phone: phone,
        };
        const status = await UpdateAddressApi(address);
        if (status === 200) {
          navigate("/payment");
        }
      }
    } catch (error) {
      toast("could not checkout due error on user address update!", {
        type: "error",
      });
    }
  };

  const isCartExist = user && Array.isArray(user.Cart) && user.Cart.length > 0;

  const displayCartItems = () => {
    if (isCartExist) {
      return (
        <div className="mt-8">
          <div className="flow-root">
            <ul role="list" className="-my-6 divide-y divide-gray-200">
              {user &&
                Array.isArray(user.Cart) &&
                user.Cart.map((item) => (
                  <li className="flex py-6">
                    <div className="h-24 w-48 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                      <img
                        src={item.ThumbnailUrl}
                        alt="course image"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>

                    <div className="ml-4 flex flex-1 flex-col">
                      <div>
                        <div className="flex justify-between text-base font-medium text-gray-900">
                          <h3>
                            <a href="#">{item.Title}</a>
                          </h3>
                          <p className="ml-4">${item.OfferPrice.toFixed(2)}</p>
                        </div>
                        <p className="mt-1 line-clamp-2 text-left h-[60px] text-sm text-gray-500 ">
                          {item.Description}
                        </p>
                      </div>
                      <div className="flex flex-1 items-end justify-between text-sm">
                        <div className="flex">
                          <button
                            onClick={() => onRemoveFromCart(item.Id)}
                            type="button"
                            className="font-medium text-blue-700 hover:text-indigo-500"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      );
    } else {
      return (
        <div className="mt-8">
          <div className="flow-root">
            <p className="text-xl">Your cart is empty!</p>
            <button
              className="btn mt-20 mb-20 bg-black text-white hover:text-black hover:bg-amber-500"
              onClick={() => navigate(`/`)}
            >
              Pick a course
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                fill="#FFFF"
              >
                <path d="M440-600v-120H320v-80h120v-120h80v120h120v80H520v120h-80ZM280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM40-800v-80h131l170 360h280l156-280h91L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68.5-39t-1.5-79l54-98-144-304H40Z" />
              </svg>
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-16 lg:max-w-7xl lg:grid-cols-2 lg:px-8 mt-10"
      style={{
        height: "80vh",
      }}
    >
      <div>
        <p className="text-left mb-5">Billing Details</p>
        <label className="form-control">
          <div className="label">
            <span className="label-text">Address</span>
          </div>
          <textarea
            onChange={(e) => setStreet(e.target.value)}
            value={street}
            className="textarea textarea-bordered h-24"
            placeholder="Address"
          ></textarea>
        </label>
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text">State</span>
          </div>
          <input
            type="text"
            onChange={(e) => setStateName(e.target.value)}
            value={stateName}
            placeholder="State"
            className="input input-bordered w-full max-w-xs"
          />
        </label>
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text">Country</span>
          </div>
          <input
            type="text"
            onChange={(e) => setCountry(e.target.value)}
            value={country}
            placeholder="Country"
            className="input input-bordered w-full max-w-xs"
          />
        </label>
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text">Phone Number</span>
          </div>
          <input
            type="tel"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            placeholder="Phone Number"
            className="input input-bordered w-full max-w-xs"
          />
        </label>
      </div>
      <div className="flex h-full flex-col overflow-y-scroll bg-white">
        <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
          <div className="flex items-start justify-between">
            <h2
              className="text-lg font-medium text-gray-900"
              id="slide-over-title"
            >
              Shopping cart
            </h2>
            <button
              className="btn bg-amber-500 hover:bg-white"
              onClick={() => navigate("/")}
            >
              Add More Course
            </button>
          </div>
          {displayCartItems()}
        </div>
        {isCartExist && (
          <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
            <div className="flex justify-between text-sm text-gray-900">
              <p>Tax & Application Fee</p>
              <p>{`$${(total().tax + total().fee).toFixed(2)}`}</p>
            </div>
            <div className="flex justify-between text-base font-medium text-gray-900">
              <p>Subtotal</p>
              <p>{`$${total().total.toFixed(2)}`}</p>
            </div>
            <p className="mt-0.5 text-sm text-gray-500">
              Invoice will be sent to your email id after payment.
            </p>
            <div
              className="mt-6"
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                style={{ width: 220 }}
                className="btn btn-block bg-black text-white hover:text-black hover:bg-amber-500"
                onClick={() => onSaveAddress()}
              >
                Checkout
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
