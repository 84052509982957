/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import logo from "../img/logo.png";
import avatar from "../img/avatar.jpg";
import { useState } from "react";
import { recordEvent } from "../utils/analytics";

export const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const navigate = useNavigate();
  const { user, cart: CartItems } = useAppSelector(
    (state) => state.userReducer
  );

  const cart = () => {
    const { amount } = user;
    if (amount) {
      const total = amount.total + amount.fee + amount.tax;
      return { totalItem: amount.totalItems, total: total };
    }
    return { totalItem: 0, total: 0 };
  };

  const displayCartPopup = () => {
    return (
      <div className="card-body bg-base-100 rounded">
        <span className="font-bold text-lg">{`${cart().totalItem} Items`}</span>
        <span className="text-black font-bold">{`Subtotal: $${cart().total.toFixed(
          2
        )}`}</span>
        <div className="card-actions">
          <button
            className="btn btn-block bg-black text-white hover:text-black hover:bg-yellow-500"
            onClick={() => navigate("/cart")}
          >
            View cart
          </button>
        </div>
      </div>
    );
  };

  const postLogin = () => {
    return (
      <div
        className="navbar bg-lime-500 fixed top-0"
        style={{
          zIndex: 100,
        }}
      >
        <div className="flex-1 ml-20">
          <img width={40} height={40} alt="Logo" src={logo} />
          <a className="text-4xl logo-font text-white" href="/">
            CodEpisodes
          </a>
        </div>

        <div className="flex-none">
          <div className="dropdown dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost btn-circle"
            >
              <div className="indicator">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="#FFF"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                <span className="badge badge-sm indicator-item">
                  {cart().totalItem}
                </span>
              </div>
            </div>
            <div
              className="mt-3 z-[220] card card-compact dropdown-content w-52 bg-base-600 shadow-xl"
              style={{
                zIndex: 500,
              }}
            >
              {displayCartPopup()}
            </div>
          </div>
          <div className="dropdown dropdown-end">
            <div
              tabIndex={500}
              role="button"
              className="btn btn-ghost btn-circle avatar"
            >
              <div className="w-10 rounded-full">
                <img alt="avatar" src={avatar} />
              </div>
            </div>
            <ul
              tabIndex={500}
              className="menu menu-sm dropdown-content mt-3 z-[220] p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li
                style={{
                  height: 42,
                }}
              >
                <a
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="justify-between hover:bg-lime-500"
                  href="/account"
                >
                  Profile
                </a>
              </li>
              <li
                style={{
                  height: 42,
                }}
              >
                <a
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="justify-between hover:bg-lime-500"
                  href="/logout"
                >
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const preLogin = () => {
    return (
      <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a
            href="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img width={32} height={32} alt="Logo" src={logo} />
            <span className="self-center text-3xl font-semibold whitespace-nowrap dark:text-white logo-font ">
              CodEpisodes
            </span>
          </a>
          <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <button
              onClick={() => {
                recordEvent("navbar", {
                  event_type: "Button_Clicked",
                  event_name: "get-started",
                  user_properties: {},
                });
                navigate("/login");
              }}
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Get started
            </button>
          </div>
          <div
            className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
            id="navbar-sticky"
          ></div>
        </div>
      </nav>
    );
  };

  if (user.token && user.UserId) {
    return postLogin();
  } else {
    return preLogin();
  }
};
